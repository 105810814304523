import posthog from 'posthog-js'

import { isIE } from '@/helpers/ieHelper'

const enabled = document.head.hasAttribute('data-posthog-enabled')

if (enabled) {
  posthog.init('phc_Ku6q50LBWiT7UziLzjeV3PQpu86jhHQNCW9EN1eBvC4', {
    api_host: '/posthog-ingest',
    ui_host: 'https://us.posthog.com',
    disable_external_dependency_loading: isIE(),
    person_profiles: 'identified_only',
    persistence: 'sessionStorage',
    capture_pageview: false,
    session_recording: {
      maskAllInputs: false,
      blockSelector: '[data-private="true"]',
    },
  })
}
