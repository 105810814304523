import '@/config/polyfills'
import '@/config/posthog'
import '@/config/cerner-mpages'
import '@/styles/bulma.scss'
import '@/styles/server.scss'

import { captureEvent } from '@/config/posthog/events'

window.addEventListener('DOMContentLoaded', () => {
  captureEvent('$pageview')
})
