import posthog, { Properties } from 'posthog-js'

interface IdentifyUserProps {
  fhirUserId: string
  username: string
  tenantSlug: string
}

const identifyUser = ({ fhirUserId, username, tenantSlug }: IdentifyUserProps) => {
  posthog.identify(fhirUserId, { name: username, tenantSlug })
}

const captureException = (exception: Error, properties?: Properties) => {
  posthog.captureException(exception, properties)
}

const captureEvent = (name: string, properties?: Properties) => {
  posthog.capture(name, properties)
}

export { identifyUser, captureException, captureEvent }
