declare global {
  interface Window {
    CernerSmartEmbeddableLib: {
      calcFrameHeight: () => number
    }
  }
}

window.addEventListener('DOMContentLoaded', () => {
  const enabled = document.head.hasAttribute('data-mpages-enabled')
  if (!enabled) return

  require('cerner-smart-embeddable-lib')

  window.CernerSmartEmbeddableLib.calcFrameHeight = function () {
    return 700
  }
})

export {}
